import React, { Component } from "react";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Sell your business</h2>
                <p>
                  Selling your business is one of the most important
                  transactions in your professional career. Whether you are
                  retiring and want to transition away from the day to day
                  operations or you run out of steam and want to hand the keys
                  to a new operator that will bring new ideas and a new life.
                  The process of selling your business needs business
                  professionals that can guide you step by step. Once you have
                  made the decision to sell there are many questions you may
                  have about the process. The most common questions is how much
                  is my business worth. To get a ballpark estimate of your
                  business valuation please click GET APPRAISAL
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
