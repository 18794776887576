import React, { Component } from "react";

export class Team extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Meet J.M. Daniel</h2>
            <p>
              J.M. Daniel is originally from London, England. He graduated from
              Central Methodist University in Fayette, Missouri with a degree in
              Political Science and obtained his Masters in Business
              Administration from Maryville University of St Louis. J.M. Daniel
              has owned a management company on Mainstreet St Charles since 2019
              and can often be seen at the OPO Startups. As the owner of a
              company J.M Daniel understands the life of a business owner, the
              ups and downs, and the experience of selling a business. After
              selling his business and a bad experience with a broker J.M.
              Daniel founded Lacuna Business Brokers on the basis that a brokers
              should provide three H's. Honesty, Humility, and Hard work. <br />
              <br /> To schedule a call with J.M. Daniel, share your journey,
              and learn more about selling your business call 636 942 5141
            </p>
          </div>
          <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
