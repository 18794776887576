import React from "react";
import { useForm } from "react-hook-form";
import emailjs, { init } from "emailjs-com";
import $ from "jquery";

const Contact = (props) => {
    init("user_jqtSC7kH3Gp3OdJsU6iVV");
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        // e.preventDefault();
        console.log(data);

        emailjs.send("service_24iie8j", "template_zzsv888", data).then(
            (response) => {
                console.log("SUCCESS!", response.status, response.text);

                // Success message
                $("#success").html("<div class='alert alert-success'>");
                $("#success > .alert-success")
                    .html(
                        "<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;"
                    )
                    .append("</button>");
                $("#success > .alert-success").append(
                    "<strong>Your message has been sent. </strong>"
                );
                $("#success > .alert-success").append("</div>");

                //clear all fields
                $("#contactForm").trigger("reset");
            },
            (error) => {
                console.log("FAILED...", error);

                alert(
                    `${error}, Your message has not been sent. Please try again.`
                );
            }
        );
    };
    const onError = (errors, e) => console.warn(errors);
    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="section-title">
                                <h2>Get FREE Appraisal</h2>
                                <p>
                                    Please fill out the form below to and we
                                    will send you an email with next steps.
                                </p>
                            </div>
                            <form
                                onSubmit={handleSubmit(onSubmit, onError)}
                                name="sentMessage"
                                id="contactForm"
                                noValidate
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                {...register("name", {
                                                    required: true,
                                                })}
                                                type="text"
                                                id="name"
                                                className="form-control"
                                                placeholder="Name"
                                                required
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                {...register("email", {
                                                    required: true,
                                                })}
                                                type="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="Email"
                                                required="required"
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                {...register("phone", {
                                                    required: true,
                                                })}
                                                id="email"
                                                className="form-control"
                                                placeholder="Phone"
                                                required="required"
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                {...register("businessName", {
                                                    required: true,
                                                })}
                                                type="text"
                                                id="email"
                                                className="form-control"
                                                placeholder="Name of Business"
                                                required="required"
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        {...register("message", {
                                            required: true,
                                        })}
                                        name="message"
                                        id="message"
                                        className="form-control"
                                        rows="4"
                                        placeholder="Message"
                                        required
                                    ></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div id="success"></div>
                                <button
                                    type="submit"
                                    className="btn btn-custom btn-lg"
                                >
                                    Contact Us
                                    {/* Get Appraisal */}
                                    {/* <a href="https://docs.google.com/forms/d/1OrLcDxFN1BmG1wbNCQ_GJHfnCTOnECyR1S0Rkr6nlyw/edit?usp=sharing">
                  </a> */}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-3 col-md-offset-1 contact-info">
                        <div className="contact-item">
                            <h3>Schedule a demo call</h3>
                            <p>
                                <span>
                                    <i className="fa fa-map-marker"></i> Address
                                </span>
                                {props.data ? props.data.address : "loading"}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-phone"></i> Phone
                                </span>{" "}
                                {props.data ? props.data.phone : "loading"}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"></i> Email
                                </span>{" "}
                                {props.data ? props.data.email : "loading"}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="social">
                                <ul>
                                    <li>
                                        <a
                                            href={
                                                props.data
                                                    ? props.data.facebook
                                                    : "/"
                                            }
                                        >
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center"></div>
            </div>
        </div>
    );
};

export default Contact;
